import { parseInt } from "lodash";

export class ConfigModel {
    API_URL: string;
    CHARGEBEE_INTEGRATION_API_URL: string;
    AUTH_URL: string;
    CONTACTS_TEMPLATE_URL_XLSX: string;
    CONTACTS_TEMPLATE_URL_CSV: string;
    CLIENT_ID: string;
    CLIENT_SECRET: string;
    RECAPTCHA_SITE_KEY: string;
    IMAGE_MAX_SIZE: number;
    IMAGE_MIN_SIZE: number;
    FILE_SIZE: number;
    ZIP_CODE_API_KEY: string;
    TOASTER_TIMEOUT: number;
    CHARGEBEE_SITE: string;
    SUPPORT_EMAIL: string;
    SALES_EMAIL: string;
    ISSUE_REPORT_EMAIL: string;
    WINDOWS_APP_LINK: string;
    MAC_APP_LINK: string;
    PLAYSTORE_APP_LINK: string;
    APPSTORE_APP_LINK: string;
    TEAMSSTORE_APP_LINK: string;
    CHROMESTORE_APP_LINK: string;
    IS_PROD_ENV: boolean;
    IS_BETA_ENV: boolean;
    IS_UAT_ENV: boolean;
    IS_DEV_ENV: boolean;
    VERSION: string;
    IMPORT_CONTACTS_AD_ID: string;
    SSO_LOGIN_AZURE_AD_CLIENT_ID: string;
    TEAMS_CHANNEL_CLIENT_ID: string;
    API_SECOND_URL: string;
    APP_FE_URL: string;
    REACT_APP_APP_FARO_URL: string;
    APP_TEAMS_APP_ID: string;
}

const Config: ConfigModel = {
    API_URL: window.location.hostname.includes("stagging") ? process.env.REACT_APP_STAGGING_API_URL : process.env.REACT_APP_API_URL,
    CHARGEBEE_INTEGRATION_API_URL: process.env.REACT_APP_CHARGEBEE_INTEGRATION_API_URL,
    AUTH_URL: process.env.REACT_APP_AUTH_URL,
    CONTACTS_TEMPLATE_URL_XLSX: process.env.REACT_APP_CONTACTS_TEMPLATE_URL_XLSX,
    CONTACTS_TEMPLATE_URL_CSV: process.env.REACT_APP_CONTACTS_TEMPLATE_URL_CSV,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
    RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    IMAGE_MAX_SIZE: parseInt(process.env.REACT_APP_IMAGE_MAX_SIZE || "0"),
    IMAGE_MIN_SIZE: parseInt(process.env.REACT_APP_IMAGE_MIN_SIZE || "0"),
    FILE_SIZE: parseInt(process.env.REACT_APP_FILE_SIZE || "0"),
    ZIP_CODE_API_KEY: process.env.REACT_APP_ZIP_CODE_API_KEY,
    TOASTER_TIMEOUT: parseInt(process.env.REACT_APP_TOASTER_TIMEOUT || "0"),
    CHARGEBEE_SITE: process.env.REACT_APP_CHARGEBEE_SITE,
    SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL,
    ISSUE_REPORT_EMAIL: process.env.REACT_APP_ISSUE_REPORT_EMAIL,
    SALES_EMAIL: process.env.REACT_APP_SALES_EMAIL,
    WINDOWS_APP_LINK: process.env.REACT_APP_WINDOWS_APP_LINK,
    MAC_APP_LINK: process.env.REACT_APP_MAC_APP_LINK,
    PLAYSTORE_APP_LINK: process.env.REACT_APP_PLAYSTORE_APP_LINK,
    APPSTORE_APP_LINK: process.env.REACT_APP_APPSTORE_APP_LINK,
    TEAMSSTORE_APP_LINK: process.env.REACT_APP_TEAMSSTORE_APP_LINK,
    CHROMESTORE_APP_LINK: process.env.REACT_APP_CHROMESTORE_APP_LINK,
    IS_PROD_ENV: process.env.REACT_APP_ENV === "production",
    IS_BETA_ENV: process.env.REACT_APP_ENV === "beta",
    IS_UAT_ENV: process.env.REACT_APP_ENV === "uat",
    IS_DEV_ENV: process.env.REACT_APP_ENV === "development",
    VERSION: process.env.REACT_APP_VERSION,
    IMPORT_CONTACTS_AD_ID: process.env.REACT_APP_IMPORT_CONTACTS_OUTLOOK_AZURE_AD_CLINT_ID,
    SSO_LOGIN_AZURE_AD_CLIENT_ID: process.env.REACT_APP_SSO_LOGIN_AZURE_AD_CLIENT_ID,
    TEAMS_CHANNEL_CLIENT_ID: process.env.REACT_APP_TEAMS_CHANNEl_CLIENT_ID,
    API_SECOND_URL: window.location.hostname.includes("stagging") ? process.env.REACT_APP_STAGGING_API_URL : process.env.REACT_APP_API_SECOND_URL,
    APP_FE_URL: process.env.REACT_APP_APP_FE_URL,
    REACT_APP_APP_FARO_URL: process.env.REACT_APP_APP_FARO_URL,
    APP_TEAMS_APP_ID: process.env.REACT_APP_TEAMS_APP_ID,
} as ConfigModel;

export default Config;

